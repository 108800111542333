/*
 * Resideo/LifeWhere
 * Copyright (C) 2018-2023 Resideo/LifeWhere
 * mailto:nathan.williams@resideo.com
 */

import React, { Component } from 'react';
import {Route, Routes, Navigate, Redirect} from "react-router-dom";
import { withRouter } from './components/Routing/withRouter';
import IdleTimer from 'react-idle-timer';
import IdleModal from './components/IdleModal';
import ConfirmationModal from './components/ConfirmationModal/ConfirmationModal';
import Sidebar from './components/Sidebar/Sidebar';
import { Landing } from './components/Landing';
import Dashboard from './components/Dashboard/Dashboard';
import Account from './components/Account/Account';
import Home from './components/Home/Home';
import Assets from './components/Assets/Assets';
import Devices from './components/Devices/Devices';
import InstallCheck from './components/Devices/InstallationCheck';
import Provisioning from './components/Provisioning/Provisioning';
import RegisterUser from './components/RegisterUser/RegisterUser';
import AccountManagement from './components/AccountManagement/Account/AccountManagement';
import Users from './components/AccountManagement/Users/Users';
import InviteHomeowner from './components/InviteHomeowner/InviteHomeowner';
import InternalTriageAlerts from './components/InternalTriageAlerts/InternalTriageAlerts';
import AlertTriageDetails from './components/InternalTriageAlerts/AlertTriageDetails';
import AlertTriageDashboard from './components/InternalTriageAlerts/AlertTriageDashboard';
import TriageAlerts from './components/TriageAlerts/TriageAlerts';
import AlertHistory from './components/AlertHistory/AlertHistory';
import AlertQuestions from './components/AlertQuestions/AlertQuestions';
import CustomerAlerts from './components/CustomerAlerts/CustomerAlerts';
import CurrentJob from './components/CurrentJob/CurrentJob';
import AddPartner from './components/AddPartner/AddPartner';
import BillingInfo from './components/BillingInfo/BillingInfo';
import Subscriptions from './components/Subscriptions/Subscriptions';
import CustomizeAlerts from './components/CustomizeAlerts/CustomizeAlerts';
import AlertMessaging from './components/AlertMessaging/AlertMessaging';

import IotTest from './components/Tools/IotTest';
import AccessKeyTool from './components/Tools/AccessKeyTool';
import ManageAssets from './components/Tools/ManageAssets';
import EmailStatus from './components/Tools/EmailStatus';
import OTALogs from './components/Tools/OTALogs';

import ACOnboarding from './components/Operations/ACOnboarding';
import ACAssetTrainingReview from './components/Operations/ACAssetTrainingReview';
import ACAlertTrainingReview from './components/Operations/ACAlertTrainingReview';
import FurnaceOnboarding from './components/Operations/FurnaceOnboarding';
import FurnaceAssetTrainingReview from './components/Operations/FurnaceAssetTrainingReview';
import FurnaceAlertTrainingReview from './components/Operations/FurnaceAlertTrainingReview';
import AirHandlerOnboarding from './components/Operations/AirHandlerOnboarding';
import AirHandlerAssetTrainingReview from './components/Operations/AirHandlerAssetTrainingReview';
import AirHandlerAlertTrainingReview from './components/Operations/AirHandlerAlertTrainingReview';

import registerServiceWorker from './registerServiceWorker';
import MainAppBar from './components/AppBar/AppBar';
import ManageFavorites from './components/AppBar/ManageFavorites';
import UserAccount from './components/AccountManagement/Account/UserAccount';
import Map from './components/Map/Map';

import AccountService from "./services/account.service";
import CustomerService from "./services/customer.service";
import PartnerService from "./services/partner.service";

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './custom.css';

import Login from "./containers/Login";
import ScheduleRepairs from './components/ScheduleRepairs/ScheduleRepairs';
import { Spinner } from 'react-bootstrap';
import { jsAsset, jsHouse, jsCurrentAccount, jsPartner } from "./componentObjects";

import ReactGA from 'react-ga';
//import { createBrowserHistory } from 'history';
import { initGA, ModalView, Event, EventNonInt } from './components/GoogleAnalytics';

import ChangePassword from './components/ChangePassword/ChangePassword';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import UserManagement from "./components/AccountManagement/Users/UserManagement";
import RegisterHomeowner from './components/RegisterUser/RegisterHomeowner';
import ConfirmationEmail from './components/Devices/ConfirmationEmail';
import Eula from './components/Eula/Eula';
import PartnerEnergyMetrics from './components/EnergyMetrics/PartnerEnergyMetrics';
import InstallReport from './components/InstallReport/InstallReport';
import DeletedAccounts from './components/DeletedAccounts/DeletedAccounts';
import DeletedSystems from './components/DeletedSystems/DeletedSystems';
import InviteAdmins from './components/Tools/InviteAdmins';
import loginService from "./services/login.service";
import userfavoriteService from "./services/userfavorite.service";
import Fleet from "./components/Fleet/Fleet";
import UpgradeOpportunities from "./components/Fleet/UpgradeOpportunities";
import FleetStateProvider from "./components/Fleet/FleetStateProvider";
import {createTheme, ThemeProvider} from "@mui/material";
import * as PropTypes from "prop-types";
import {ProtectedRoute} from "./components/Routing/ProtectedRoute";
import Training from "./components/Training/Training";
import FailedInstalls from "./components/FailedInstalls/FailedInstalls";
import {DataProvider} from "./components/Contexts/DataProvider";
import withDataContext from "./components/Contexts/WithDataContext";
import Loader from "./components/Loader/Loader";
import OperationalDashboard from "./components/Operations/OperationalDashboard";

//import { IotTest } from './components/Tools/IotTest';

//const history = createBrowserHistory();

/* Initialize google analytics page view tracking
const trackingId = "G-0PGQM1LVZS";
ReactGA.initialize(trackingId, { debug: true });
ReactGA.pageview(window.location.pathname);*/

/*history.listen(location => {
    var location = location.location;
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
});*/

//ReactGA.set({
//    userName: localStorage.getItem('username') || null,
//    isPartnerAdmin: localStorage.getItem('isPartnerAdmin') || false,
//    /* any data that is relevant to the user session
//    that you would like to track with google analytics*/
//    });

/*const ProtectedRoute = ({ render: Comp, loggedIn, path, ...rest }) => {
    return (
        <Route
            path={path}
            {...rest}
            render={props => {
                return loggedIn ? (
                    <Comp {...props} />
                ) : (
                        <Navigate
                            to={{
                                pathname: "/login",
                                state: {
                                    prevLocation: path,
                                    error: "You need to login first!",
                                },
                            }}
                        />
                    );
            }}
        />
    );
};*/

class App extends Component {
    static displayName = App.name;
    static baseUrl;
    _isMounted = false;
    
    constructor(props) {
        super(props);
        this.state = {
            currentPartnerId: localStorage.getItem('currentPartnerId') || "-1", 
            currentCustomerId: localStorage.getItem('currentCustomerId') || "-1",
            currentAccount: {
                firstName: localStorage.getItem('firstName') || null,
                lastName: localStorage.getItem('lastName') || null,
                email: localStorage.getItem('email') || null,
                picture: localStorage.getItem('picture') || null 
            },
            serviceTier: localStorage.getItem('serviceTier') || "-1",
            hasServiceTier: false,
            contractType: localStorage.getItem('contractType') || "",
            hasServiceTitan: false,
            hasBilling: false,
            isActive: false,
            loggedInCustomerOrganization: localStorage.getItem('loggedInCustomerOrganization') || "",
            loggedInCustomerOrganizationId: localStorage.getItem('loggedInCustomerOrganizationId') || "0",
            isPartnerAdmin: localStorage.getItem('isPartnerAdmin') === 'true' || false,
            isPortalAdmin: localStorage.getItem('isPortalAdmin') === 'true' || false,
            isCustomerSupport: localStorage.getItem('isCustomerSupport') === 'true' || false,
            userName: localStorage.getItem('username') || null,
            userId: localStorage.getItem('userId') || null, 
            roles: JSON.parse(localStorage.getItem('roles')) || [],
            loading: true,
            newLogin: false,
            loggedIn: JSON.parse(localStorage.getItem('isLoggedIn')) || false,
            timeout: 1000 * 15 * 60,
            showIdleModal: false,
            isTimedOut: false,
            registerUserId: -1,
            registerOrgId: -1,
            registerParentOrgId: -1,
            regUserLatitude: "",
            regUserLongitude: "",
            changePasswordUri: null,
            changePasswordIsNewUser: false,
            logo: localStorage.getItem('logo') || null,
            burgerClicked: false,
            reloadSidebar: true,
            forgotPassword: false,
            passwordEmailSent: false,
            isMobile: false,
            reparentStatus: "Loading Customer Account",

            showAllTraining: true,

            isLoadingComplete: false,
            activePath: localStorage.getItem('activePath') || "/",
            partnerList: [],
            customerList: [],
            favoritesList: localStorage.getItem('favorites') || [],
            favoriteCustomers: JSON.parse(localStorage.getItem('favoriteCustomers') || "[]"),
            favoriteAssets: JSON.parse(localStorage.getItem('favoriteAssets') || "[]"),
            faveAssetPath: localStorage.getItem('faveAssetPath') || [],
            rentalAgreementCustomerValue : -1,
            attributeList: [],
            lastPartnerObj: {},

            unsavedChanges: localStorage.getItem('unsavedChanges') === 'true' || false,
            showUnsavedModal: false,
            nextPath: localStorage.getItem('nextPath') || null
        };

        //for idle logout 
        this.idleTimer = null;
        this.handleOnAction = this.handleOnAction.bind(this);
        this.handleOnActive = this.handleOnActive.bind(this);
        this.handleOnIdle = this.handleOnIdle.bind(this);
        this.handleBurgerClick = this.handleBurgerClick.bind(this);
        this.onShowIdleModal = this.onShowIdleModal.bind(this);
        this.onLoggedInUserChange = this.onLoggedInUserChange.bind(this);
        this.onLogOff = this.onLogOff.bind(this);
        this.setAccountInfo = this.setAccountInfo.bind(this);

        this.setUnsavedChanges = this.setUnsavedChanges.bind(this);
        this.showUnsavedModal = this.showUnsavedModal.bind(this);

        this.onForgotPassword = this.onForgotPassword.bind(this);
        this.passwordEmailSent = this.passwordEmailSent.bind(this);
        this.endForgotPassword = this.endForgotPassword.bind(this);

        this.onContinueFromComponent = this.onContinueFromComponent.bind(this);
        this.onContinueFromAccount = this.onContinueFromAccount.bind(this); 
        this.onContinueFromUserAccount = this.onContinueFromUserAccount.bind(this);
        this.onContinueFromAddPartner = this.onContinueFromAddPartner.bind(this);

        this.switchToNewPartner = this.switchToNewPartner.bind(this);
        this.setCurrentPartnerId = this.setCurrentPartnerId.bind(this);
        this.setCurrentCustomerId = this.setCurrentCustomerId.bind(this);
        this.setCurrentPartnerAndCustomer = this.setCurrentPartnerAndCustomer.bind(this);
        this.setAssetFromFavorites = this.setAssetFromFavorites.bind(this);
        this.setLoadingFromChild = this.setLoadingFromChild.bind(this);
        this.setActivePath = this.setActivePath.bind(this);
        this.setServiceTier = this.setServiceTier.bind(this);
        this.setContractType = this.setContractType.bind(this);
        this.setServiceTitan = this.setServiceTitan.bind(this);
        this.setBilling = this.setBilling.bind(this);
        this.setIsActive = this.setIsActive.bind(this);
        this.setFavoritesList = this.setFavoritesList.bind(this);
        this.onSaveFaves = this.onSaveFaves.bind(this);
        this.setReparent = this.setReparent.bind(this);
        this.setShowAllTraining = this.setShowAllTraining.bind(this);
        this.AgreementCustomerValue = this.AgreementCustomerValue.bind(this);
        this.setAttributeList = this.setAttributeList.bind(this);
        
        this.getDataObj = this.getDataObj.bind(this);
        this.getGraphData = this.getGraphData.bind(this);
        this.getLastGraphData = this.getLastGraphData.bind(this);
    }

    UNSAFE_componentWillMount() {
        initGA();

        this.baseUrl = window.location.href;
        
        if (this.state.loggedIn === true && (this.state.currentAccount.email === "" || this.state.currentAccount.email === null || this.state.currentAccount.email === "null"))
            this.setState({ loggedIn: false });

        if (this.baseUrl.toLowerCase().indexOf("registration") >= 0) {
            let urlParams = new URLSearchParams(this.baseUrl.substring(this.baseUrl.indexOf('?')));
            let myParam = urlParams.get('email');

            if (myParam != null) {
                this.setState({ registerUserId: myParam });
            }

            if (this.baseUrl.toLowerCase().indexOf("homeownerregistration") >= 0) {
                let myParam2 = urlParams.get('orgid');
                if (myParam2 != null) {
                    this.setState({ registerOrgId: myParam2 });
                }

                let parentParam = urlParams.get('parentOrgId');
                if (parentParam != null) {
                    this.setState({ registerParentOrgId: parentParam });
                }

                let latParam = urlParams.get('latitude');
                if (latParam != null) {
                    this.setState({ regUserLatitude: latParam });
                }

                let longParam = urlParams.get('longitude');
                if (longParam != null) {
                    this.setState({ regUserLongitude: longParam });
                }
            }
        }
        else if (this.baseUrl.toLowerCase().indexOf("changepassword") >= 0) {
            let urlParams = new URLSearchParams(this.baseUrl.substring(this.baseUrl.indexOf('?')));
            let myParam = urlParams.get('email');

            if (myParam != null) {
                this.setState({ registerUserId: myParam });
            }      

            let myUriParam = urlParams.get('uri');
            if (myUriParam != null) {
                this.setState({ changePasswordUri: myUriParam });
            }

            let myMobileParam = urlParams.get('ismobile');
            if (myMobileParam != null) {
                this.setState({ isMobile: myMobileParam });
            }

            let myNewUserParam = urlParams.get('isNewUser');
            if (myNewUserParam != null) {
                this.setState({ changePasswordIsNewUser: myNewUserParam });
            }
        }
        else if (this.baseUrl.toLowerCase().indexOf("training") >= 0) {
            let urlParams = new URLSearchParams(this.baseUrl.substring(this.baseUrl.indexOf('?')));

            let myMobileParam = urlParams.get('ismobile');
            if (myMobileParam != null) {
                this.setState({ isMobile: myMobileParam });
            }
        }
        else if (this.baseUrl.toLowerCase().indexOf("forgotpassword") >= 0) {
            this.setState({ forgotPassword: true });

            let urlParams = new URLSearchParams(this.baseUrl.substring(this.baseUrl.indexOf('?')));
            let myMobileParam = urlParams.get('ismobile');
            if (myMobileParam != null) {
                this.setState({ isMobile: myMobileParam, forgotPassword: true });
            }
        }
    }

    componentDidMount() {
        this._isMounted = true;
        const config = {
            onUpdate(registration) {
                if (registration && registration.waiting) {
                        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
                        window.location.reload(true);
                }
            }
        };

        if ((this.state.currentAccount.email === "" || this.state.currentAccount.email === null || this.state.currentAccount.email === "null"))
            this.setState({ loggedIn: false });
        registerServiceWorker(config);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setLoadingFromChild(value) {
        this.setState({ loading: value });
    }

    setReparent(statusMessage) {
        this.setState({
            reparentStatus: statusMessage
        });
    }

    AgreementCustomerValue(Value) {
       
        this.setState({
            rentalAgreementCustomerValue: parseInt(Value)
        });
    }
    setAttributeList(attrlist) {
        this.setState({ attributeList: attrlist });
     }

    setAttributeList(attrlist) {
        this.setState({ attributeList: attrlist });
    }

    switchToNewPartner(partnerList, partner) {
        if (partnerList !== undefined && partner !== undefined) {
            this.setState({ partnerList: partnerList }, () => {
                this.setState({
                    currentPartnerId: partner !== undefined ? partner.organizationId : "-1",
                    currentCustomerId: "-1"
                },
                    () => {
                        this.setState({ activePath: "/accountManagement", reloadSidebar: true }, () => {
                            localStorage.setItem('activePath', "/accountManagement");
                            localStorage.setItem('currentPartnerId', this.state.currentPartnerId);
                            this.setContractType(this.state.currentPartnerId);
                            this.props.navigate("/accountManagement");
                        });
                    });
            });
        }
        
    }

    onLoggedInUserChange(e) {
        this.setState({ roles: e.roles, userName: e.userName, userId: e.userId, logo: "data:image/png;base64," + e.logo }, () => {

            this.setState({ loggedIn: true, loggedInCustomerOrganization: e.organization, loggedInCustomerOrganizationId: e.organizationId,
                isPartnerAdmin: this.state.roles.includes("PARTNER ADMIN"), isPortalAdmin: this.state.roles.includes("PORTAL ADMIN"), isCustomerSupport: this.state.roles.includes("CUSTOMER SUPPORT"), currentPartnerId: "-1", currentCustomerId: "-1", contractType: "", showAllTraining: true }, () => {
                localStorage.setItem('isLoggedIn', true);
                localStorage.setItem('roles', JSON.stringify(this.state.roles));
                localStorage.setItem('username', this.state.userName);
                localStorage.setItem('userId', this.state.userId);
                localStorage.setItem('loggedInCustomerOrganization', this.state.loggedInCustomerOrganization);
                localStorage.setItem('loggedInCustomerOrganizationId', this.state.loggedInCustomerOrganizationId);
                localStorage.setItem('logo', this.state.logo);
                localStorage.setItem('isPartnerAdmin', this.state.isPartnerAdmin);
                localStorage.setItem('isPortalAdmin', this.state.isPortalAdmin);
                localStorage.setItem('isCustomerSupport', this.state.isCustomerSupport);
                localStorage.setItem('currentPartnerId', "-1");
                localStorage.setItem('currentCustomerId', "-1");
                localStorage.setItem('showAllTraining', true);
                localStorage.setItem('activePath', "/");
            });            
        });
    }

    setAccountInfo = (user) => {
        let newName = user.firstName !== null && user.firstName !== "" ? user.firstName : user.email;
        this.setState({ currentAccount: user, userName: newName, logo: "data:image/png;base64," + user.picture }, async () => {
            localStorage.setItem('username', this.state.userName);
            localStorage.setItem('logo', this.state.logo);
            localStorage.setItem('firstName', this.state.currentAccount.firstName); 
            localStorage.setItem('lastName', this.state.currentAccount.lastName);
            localStorage.setItem('email', this.state.currentAccount.email);
            localStorage.setItem('picture', this.state.currentAccount.picture);

            if (this.state.currentAccount.email === null || this.state.currentAccount.email === undefined || this.state.currentAccount.email === "null") {
                this.onLogOff();
            }

            /*await AccountService.getuserfavorites(this.state.currentAccount.email)
                .then(response => {
                    this.setState({ favoritesList: response.data }, () => {
                        this.sortFavorites();
                    });
                })
                .catch(e => {
                    console.log(e);
                });*/
        });
    }

    setCurrentPartnerId(id, setPartnerCallback, reloadList = false) {
        // This should only be for when the Partners selection in the SideNav is changed
        // If it's just a page refresh, this method gets called but we want to maintain the Customer selected.
        if (this.state.currentPartnerId !== id) {
            this.setState({ currentCustomerId: "-1" });
        }

        if (this.state.partnerList.findIndex(p => p.organizationId === id) < 0) {
            let partnerObj = {
                organization: this.state.loggedInCustomerOrganization,
                isPartnerAdmin: this.state.isPartnerAdmin,
                isActive: true
            };
            if (reloadList) {
                this.setState({ loading: true, lastPartnerObj: partnerObj }, async () => {
                    await PartnerService.get(partnerObj) //this.props.loggedInCustomerOrganization, this.props.isPartnerAdmin, true) 
                        .then(response2 => {
                            if (response2.status === 401) {
                                this.onLogOff();
                            }
                            else {
                                let newPartnerList = response2.table;
                                this.setState({ partnerList: newPartnerList });
                            }
                        })
                        .catch(e => {
                            console.log(e);
                            this.onLogOff();
                        });
                });
            }
        }

        this.setState({ currentPartnerId: id },
	         () => {
            localStorage.setItem('currentPartnerId', this.state.currentPartnerId);
            localStorage.setItem('currentCustomerId', this.state.currentCustomerId); //todo check 
            localStorage.setItem("unsavedChanges", false);
            localStorage.setItem("showUnsavedModal", false);
            localStorage.setItem("nextPath", null);
            localStorage.setItem("currentPartner", JSON.stringify(jsPartner));
            localStorage.setItem("partnerProps", {});
            this.setContractType(this.state.currentPartnerId);
            setPartnerCallback();
        });                
    }

    setCurrentCustomerId(id, setCustomerCallback) {
        this.setState({ currentCustomerId: id }, () => {
            localStorage.setItem('currentCustomerId', this.state.currentCustomerId);
            setCustomerCallback();
        });
    }

    setCurrentPartnerAndCustomer(id, customerId, setPartnerCallback) {
        /* In case we want the list cleared so no other customer name pops up briefly
        if (id !== this.state.currentPartnerId) {
            console.log("ID, CURRENTPARTNERID ", id, this.state.currentPartnerId);
            this.setState({customerList:[]})
        }
        */
        //this.getCustomerList(id);
        let selectedCustomerId = customerId;
        if(customerId === 0){
            selectedCustomerId = this.state.customerList.length > 0 ? this.state.customerList[selectedCustomerId].organizationId : -1;
        }
        this.setState({
            currentPartnerId: id, currentCustomerId: selectedCustomerId
        }, async () => {
            localStorage.setItem('currentPartnerId', this.state.currentPartnerId);
            localStorage.setItem('currentCustomerId', this.state.currentCustomerId);
            this.setContractType(this.state.currentPartnerId);
            setPartnerCallback();
        });
    }

    setAssetFromFavorites(id, customerId, asset, setAssetCallback) {
        let assetPath = [asset.houseId, asset.hvacId, asset.assetId];
        this.setState({ currentPartnerId: id, currentCustomerId: customerId, faveAssetPath: assetPath }, async () => {
            localStorage.setItem('currentPartnerId', this.state.currentPartnerId);
            localStorage.setItem('currentCustomerId', this.state.currentCustomerId);
            localStorage.setItem('faveAssetPath', JSON.stringify(this.state.faveAssetPath));
            this.setContractType(this.state.currentPartnerId);
            setAssetCallback();
        });
    }

    setFavoritesList(list) {
        this.setState({ favoriteCustomers: list });
    }

    setAssetFavorites(list) {

    }

    setShowAllTraining(showAll) {
        this.setState({ showAllTraining: showAll }, () => {
            localStorage.setItem('showAllTraining', this.state.showAllTraining);
        });
    }

    /*sortFavorites() {
        let favorites = this.state.favoritesList;
        if (favorites.length) {
            let customers = favorites.filter(f => f.favoriteTypeId === 1);
            let assets = favorites.filter(f => f.favoriteTypeId === 2);
            if (this._isMounted)
                this.setState({
                    favoriteCustomers: customers,
                    favoriteAssets: assets
                });
        }
    }*/

    getStorageValue(key, defaultValue) {
        const saved = localStorage.getItem(key);
        const value = saved !== null ? saved : defaultValue;
        return value;
    }

    onContinueFromComponent(setPathCallback) {
        let nextPath = this.getStorageValue("nextPath", null);
        if (nextPath === null || nextPath === 'null') {
            console.log('no nextPath set.');
        }
        else { 
            this.setState({ 
                loading: true, 
                activePath: nextPath, 
                nextPath: null, 
                unsavedChanges: false, 
                showUnsavedModal: false 
            }, () => {
                setPathCallback();
                localStorage.setItem("activePath", this.state.activePath);
                localStorage.setItem("unsavedChanges", this.state.unsavedChanges);
                localStorage.setItem("showUnsavedModal", this.state.showUnsavedModal);
                this.setState({ loading: false });
            })
        }
    }

    onContinueFromAccount(id, customerId, successMsg, setPartnerCallback) {
        let nextPath = this.getStorageValue("nextPath", null);
        if (nextPath === null || nextPath === 'null') {
            console.log('no nextPath set.');
        }
        else {
            if (id === null) {
                this.onContinueFromComponent(setPartnerCallback);
            }
            else {
                //this.getCustomerList(id, true);
                this.setState({ 
                    //loading: true,
                    reparentStatus: successMsg, 
                    currentPartnerId: id, 
                    currentCustomerId: customerId,
                    activePath: nextPath,
                    nextPath: null,
                    unsavedChanges: false,
                    showUnsavedModal: false
                }, () => {
                    setPartnerCallback();
                        localStorage.setItem("currentPartnerId", this.state.currentPartnerId);
                        localStorage.setItem("currentCustomerId", this.state.currentCustomerId);
                        localStorage.setItem("activePath", this.state.activePath);
                        //localStorage.setItem("nextPath", this.state.nextPath);
                        localStorage.setItem("unsavedChanges", this.state.unsavedChanges);
                        localStorage.setItem("showUnsavedModal", this.state.showUnsavedModal);
                        this.setContractType(this.state.currentPartnerId);
                        this.setState({ loading: false });
                });
            }
        }
    }

    onContinueFromUserAccount(user, setPathCallback) {
        let nextPath = this.getStorageValue("nextPath", null);
        if (nextPath === null || nextPath === 'null') {
            console.log('no nextPath set.')
        }
        else {
            this.setAccountInfo(user);
            this.onContinueFromComponent(setPathCallback);
        }
    }

    onContinueFromAddPartner(partnerList, setPartnerCallback) {
        let nextPath = this.getStorageValue("nextPath", null);
        if (nextPath === null || nextPath === 'null') {
            console.log('no nextPath set.')
        }
        else {
            this.setState({ loading: true, partnerList: partnerList}, () => {
                this.onContinueFromComponent(setPartnerCallback);
            });
        }
    }

    setActivePath(newPath, fromSidebar = false) {
        let unsavedChanges = this.getStorageValue("unsavedChanges", false) === 'true';
        
        if (fromSidebar && unsavedChanges) {
            localStorage.setItem("showUnsavedModal", true);
            localStorage.setItem("nextPath", newPath);
            localStorage.setItem("activePath", this.state.activePath);
            this.setState({ showUnsavedModal: true, nextPath: newPath });
        }
        else {
            this.setState({ activePath: newPath, showUnsavedModal: false, unsavedChanges: false, nextPath: null }, () => {
                localStorage.setItem('activePath', this.state.activePath);
                localStorage.setItem("unsavedChanges", false);
                localStorage.setItem("showUnsavedModal", false);
                localStorage.setItem("nextPath", null);
            });
        }
    }

    setServiceTier(tier) {
        let hasServiceTier = false;
        let serviceTier = parseInt(tier, 10);
        if (serviceTier > 0) 
            hasServiceTier = true;

        this.setState({ serviceTier: serviceTier, hasServiceTier: hasServiceTier });
    }
    
    setContractType(partnerId) {
        let contractType = "";
        if (this.state.attributeList !== null && this.state.attributeList !== undefined && this.state.attributeList.length) {
            if (this.state.attributeList.some(a => a.name === "ContractType" && a.organizationId === partnerId)) {
                contractType = this.state.attributeList.find(a => a.name === "ContractType" && a.organizationId === partnerId).value;
            }
        }
        this.setState({ contractType: contractType });
    }

    setServiceTitan(hasST) {
        this.setState({ hasServiceTitan: hasST });
    }

    setBilling(hasBilling) {
        this.setState({ hasBilling: hasBilling });
    }

    setIsActive(isActive) {
        this.setState({ isActive: isActive });
    }

    onSaveFaves(newList) {
        this.setState({ favoritesList: newList, loading: true }, async () => {
            //this.sortFavorites();
            await userfavoriteService.updateuserfavorites(this.state.userName, newList)
                .then(response => {
                    if (response.data === true) {
                        this.setState({ loading: false });
                    }
                })
                .catch(e => {
                    console.log(e);
                })
        })
    }

    onForgotPassword() {
        this.setState({ activePath: "/forgotPassword", forgotPassword: true }, () => {
            localStorage.setItem('activePath', this.state.activePath);
        });
    }

    passwordEmailSent(sent) {
        this.setState({ passwordEmailSent: sent });
    }

    endForgotPassword() {
        this.setState({ activePath: "/", forgotPassword: false, passwordEmailSent: false, loggedIn: false }, () => {
            localStorage.setItem('activePath', "/");
            localStorage.setItem('isLoggedIn', false);
            this.props.navigate("/");
            if (this.baseUrl.toLowerCase().indexOf("registration"))
                window.location.reload(true);
        });
    }

    //for idle logout
    handleOnAction() { 
        console.log('user did something');
        this.setState({ isTimedOut: false });
    }

    handleOnActive() {
        console.log('user is active');
        console.log('time remaining', this.idleTimer.getRemainingTime());
        this.setState({ isTimedOut: false });
    }

    handleOnIdle() {
        console.log('user is idle');
        console.log('last active', this.idleTimer.getLastActiveTime());
        const isTimedOut = this.state.isTimedOut;
        if (isTimedOut) {
            EventNonInt("Idle Logout", "Idle timer forced user logout", "Idle Logout", true);
            this.onLogOff();
        }
        else {
            this.setState({ showIdleModal: true });
            ModalView("idle");
            this.idleTimer.reset();
            this.setState({ isTimedOut: true });
        }

    }

    onShowIdleModal = e => {
        this.setState({
            showIdleModal: !this.state.showIdleModal,
            isTimedOut: false 
        });
        window.scrollTo(0, 0);
    }

    handleBurgerClick() {
        this.setState({
            burgerClicked: !this.state.burgerClicked,
            reloadSidebar: false,
            loading: false
        });
    }

    setUnsavedChanges(hasUnsavedChanges) {
        this.setState({
            unsavedChanges: hasUnsavedChanges,
            showUnsavedModal: hasUnsavedChanges ? this.state.showUnsavedModal : false
        });
    }

    showUnsavedModal() {
        this.setState({
            showUnsavedModal: !this.state.showUnsavedModal
        });
    }

    goNextPath() {

    }

    async onLogOff() {
        if (!this.state.isTimedOut)
            Event("User Logout", "User clicked logout", "User Logout Option Clicked");
            this.setState({ loggedIn: false, roles: [], userName: null, userId: null, homeList: [], hvacList: [], assetList: [], currentAccount: jsCurrentAccount, currentHVACSystem: jsHouse, currentHome: jsHouse, currentAsset: jsAsset, activePath: "/", favoriteCustomers: [] },
                () => {
                    localStorage.setItem('isLoggedIn', false);
                    this.props.navigate("/");
                    this.setState({ showIdleModal: false });
                    localStorage.setItem('token', null);
                    localStorage.setItem('currentPartnerId', "-1");
                    localStorage.setItem('currentCustomerId', "-1");
                    localStorage.setItem('username', null);
                    localStorage.setItem('userId', null);
                    localStorage.setItem('roles', JSON.stringify([]));
                    localStorage.setItem('loggedInCustomerOrganization', "");
                    localStorage.setItem('isPartnerAdmin', false);
                    localStorage.setItem('isPortalAdmin', false);
                    localStorage.setItem('isCustomerSupport', false);
                    localStorage.setItem('logo', null);
                    localStorage.setItem('firstName', null);
                    localStorage.setItem('lastName', null);
                    localStorage.setItem('email', null);
                    localStorage.setItem('picture', null);
                    localStorage.setItem('faveAssetPath', JSON.stringify([]));
                    localStorage.setItem('activePath', "/");
                    localStorage.setItem('favoriteCustomers', JSON.stringify([]));
                    localStorage.setItem('favoriteAssets', JSON.stringify([]));
                });
    }    
    
    /** Graph Helpers **/
    getDataObj(name, dataArr, color, dashStyle = 'solid', pointStart = null) {
        let dataObj = {
            name: name,
            data: dataArr,
            color: color,
            dashStyle: dashStyle,
            type: 'line',
            opacity: 1,
            marker: {
                enabledThreshold: 0
            },
            yAxis: 0
        };
        if (pointStart !== null) {
            dataObj.pointStart = pointStart;
            dataObj.pointInterval = 1000;
        }
        return dataObj;
    }

    getGraphData(dataList, dataName, seriesRun, seriesColor, valueStr = 'value', dashStyle = 'solid', pointStart = null) {
        let dataArr = seriesRun.map((item) => (item[valueStr]));

        const dataObj = this.getDataObj(dataName, dataArr, seriesColor, dashStyle, pointStart);
        dataList = dataList.concat(dataObj);
        return dataList;
    }

    getLastGraphData(dataList, dataName, seriesRun, seriesColor, valueStr = 'value', dashStyle = 'solid', pointStart = null) {
        let dataArr = seriesRun.map((item) => (item[valueStr]));
        let lastArr = [...dataArr].slice(dataArr.length >= 151 ? dataArr.length - 151 : 0);

        const dataObj = this.getDataObj(dataName, lastArr, seriesColor, dashStyle, pointStart);
        dataList = dataList.concat(dataObj);
        return dataList;
    }

    render() {

        let contents = this.state.loading ?
            <Loader />
            : <div>{null}</div>

        return this.baseUrl.toLowerCase().indexOf("eula") >= 0 ?
            <Eula />
            : this.baseUrl.toLowerCase().indexOf("registration") >= 0 ?
                (this.baseUrl.toLowerCase().indexOf("homeownerregistration") >= 0 ?
                    <RegisterHomeowner registerUserId={this.state.registerUserId} registerOrgId={this.state.registerOrgId} registerParentOrgId={this.state.registerParentOrgId} latitude={this.state.regUserLatitude} longitude={this.state.regUserLongitude} />
                    : <RegisterUser registeruserId={this.state.registerUserId} />)
                : this.baseUrl.toLowerCase().indexOf("changepassword") >= 0
                    ? <ChangePassword registerUserId={this.state.registerUserId} changePasswordUri={this.state.changePasswordUri} currentAccount={this.state.currentAccount} endForgotPassword={this.endForgotPassword} isMobile={this.state.isMobile} isNewUser={this.state.changePasswordIsNewUser}/>
                    : this.baseUrl.toLowerCase().indexOf("training") >= 0 && this.state.isMobile 
                        ? <Training currentUserId={this.state.userId} isLoading={this.state.loading} roles={this.state.roles} isMobile={this.state.isMobile} />
                    : this.state.forgotPassword ?
                        (<ForgotPassword passwordEmailSent={this.passwordEmailSent} endForgotPassword={this.endForgotPassword} isMobile={this.state.isMobile} />)
                        : this.state.loggedIn ? (
                            <DataProvider>
                            <div>

                                {contents}

                                <IdleTimer
                                    ref={ref => { this.idleTimer = ref }}
                                    timeout={this.state.timeout}
                                    onActive={this.handleOnActive}
                                    onIdle={this.handleOnIdle}
                                    debounce={500}
                                />
                                <React.Fragment>
                                        <MainAppBar 
                                            userName={this.state.userName} 
                                            logo={this.state.logo} 
                                            onLogOff={this.onLogOff} 
                                            currentAccount={this.state.currentAccount} 
                                            currentCustomerId={this.state.currentCustomerId} 
                                            roles={this.state.roles} 
                                            loggedInCustomerOrganization={this.state.loggedInCustomerOrganization} 
                                            isPartnerAdmin={this.state.isPartnerAdmin} 
                                            serviceTier={this.state.serviceTier} 
                                            handleBurgerClick={this.handleBurgerClick} 
                                            setActivePath={this.setActivePath} 
                                            favorites={this.state.favoriteCustomers} 
                                            favoriteCustomers={this.state.favoriteCustomers} 
                                            favoriteAssets={this.state.favoriteAssets} 
                                            customers={this.state.customerList} 
                                            partners={this.state.partnerList} 
                                            setCurrentPartnerId={this.setCurrentPartnerId} 
                                            setCurrentCustomerId={this.setCurrentCustomerId} 
                                            setCurrentUser={this.setAccountInfo} 
                                            selectedPartner={this.state.currentPartnerId} 
                                            setFromFavorites={this.setCurrentPartnerAndCustomer} 
                                            setAssetFromFavorites={this.setAssetFromFavorites} 
                                            setFavoritesList={this.setFavoritesList} />
                                        {
                                            <div>

                                                <div sx={ (theme) => 
                                                (this.state.burgerClicked  ? {
                                                        display: 'block',
                                                        [theme.breakpoints.down('md')]: {
                                                            width: '0px !important',
                                                            display: 'none'
                                                        },
                                                    }:{
                                                        zIndex: '9',
                                                        [theme.breakpoints.down('md')]: {
                                                            display: 'block',
                                                            width: '225px !important'
                                                        },
                                                    })} >
                                                    <Sidebar 
                                                        setLoadingFromChild={this.setLoadingFromChild} 
                                                        onLogOff={this.onLogOff} 
                                                        userName={this.state.currentAccount.email} 
                                                        currentPartnerId={this.state.currentPartnerId} 
                                                        currentCustomerId={this.state.currentCustomerId} 
                                                        roles={this.state.roles}
                                                        loggedInCustomerOrganization={this.state.loggedInCustomerOrganization} 
                                                        isPartnerAdmin={this.state.isPartnerAdmin} 
                                                        reloadSidebar={this.state.reloadSidebar} 
                                                        setCurrentPartnerAndCustomer={this.setCurrentPartnerAndCustomer}
                                                        setCurrentPartnerId={this.setCurrentPartnerId}
                                                        setCurrentCustomerId={this.setCurrentCustomerId} 
                                                        setPartnerList={list => { this.setState({ partnerList: list }); }} 
                                                        setPartnerObj={obj => { this.setState({ lastPartnerObj: obj }); }} 
                                                        lastPartnerObj={this.state.lastPartnerObj} 
                                                        setServiceTier={this.setServiceTier} 
                                                        setContractType={this.setContractType} 
                                                        setServiceTitan={this.setServiceTitan} 
                                                        setBilling={this.setBilling} 
                                                        setIsActive={this.setIsActive}
                                                        setCustomerList={list => { this.setState({ customerList: list }); }}
                                                        activePath={this.state.activePath} 
                                                        setActivePath={this.setActivePath} 
                                                        detailedCustomerList={this.state.detailedCustomerList}
                                                        hasServiceTier={this.state.hasServiceTier} 
                                                        hasBilling={this.state.hasBilling} 
                                                        isActive={this.state.isActive} 
                                                        setCurrentUser={this.setAccountInfo} 
                                                        AgreementCustomerValue={this.AgreementCustomerValue} 
                                                        setAttributeList={this.setAttributeList} 
                                                        nextPath={this.state.nextPath} >

                                                        <Routes>
                                                            <Route element={<ProtectedRoute isAuthenticated={this.state.loggedIn} />}>
                                                                <Route path="/" element={
                                                                    <Landing isPortalAdmin={this.state.isPortalAdmin} roles={this.state.roles} />
                                                                }/>
                                                                <Route exact path="/monitoringDashboard" element = {
                                                                    <Dashboard  currentAccount={this.state.currentAccount} currentPartnerId={this.state.currentPartnerId} setActivePath={this.setActivePath} setServiceTier={this.setServiceTier}  serviceTier={this.state.serviceTier} hasServiceTier={this.state.hasServiceTier} contractType={this.state.contractType} hasBilling={this.state.hasBilling} />
                                                                }/>
                                                                <Route exact path="/lwTriageAlerts" element = {
                                                                    <InternalTriageAlerts  partners={this.state.partnerList}  userId={this.state.userId} currentUser={this.state.currentAccount} getGraphData={this.getGraphData} getLastGraphData={this.getLastGraphData}/>
                                                                }/>
                                                                <Route exact path="/alertTriageDetails" element = {
                                                                    <AlertTriageDetails   />
                                                                } />
                                                                <Route exact path="/alertTriageDashboard" element = {
                                                                    <AlertTriageDashboard   setActivePath={this.setActivePath} _isMounted={this._isMounted} />
                                                                } />
                                                                <Route path="/triageAlerts" element = {
                                                                    <TriageAlerts  currentPartnerId={this.state.currentPartnerId} currentCustomerId={this.state.currentCustomerId} setCurrentPartnerAndCustomer={this.setCurrentPartnerAndCustomer} setCurrentPartnerId={this.setCurrentPartnerId} setCurrentCustomerId={this.setCurrentCustomerId} customers={this.state.customerList} partners={this.state.partnerList} setActivePath={this.setActivePath}  userOrg={this.state.loggedInCustomerOrganization} userName={this.state.currentAccount.email} hasServiceTitan={this.state.hasServiceTitan} serviceTier={this.state.serviceTier} hasServiceTier={this.state.hasServiceTier} setServiceTier={this.setServiceTier/*tier => { this.setState({ serviceTier: tier }); }*/} />
                                                                } />
                                                                <Route path="/triageAlerts/:type" element = {
                                                                    <TriageAlerts  currentPartnerId={this.state.currentPartnerId} currentCustomerId={this.state.currentCustomerId} setCurrentPartnerAndCustomer={this.setCurrentPartnerAndCustomer} setCurrentPartnerId={this.setCurrentPartnerId} setCurrentCustomerId={this.setCurrentCustomerId} customers={this.state.customerList} partners={this.state.partnerList} setActivePath={this.setActivePath}  userOrg={this.state.loggedInCustomerOrganization} userName={this.state.currentAccount.email} hasServiceTitan={this.state.hasServiceTitan} serviceTier={this.state.serviceTier} hasServiceTier={this.state.hasServiceTier} setServiceTier={this.setServiceTier/*tier => { this.setState({ serviceTier: tier }); }*/} />
                                                                } />
                                                                <Route exact path="/alertHistory" element = {
                                                                    <AlertHistory  currentPartnerId={this.state.currentPartnerId} currentCustomerId={this.state.currentCustomerId} setCurrentPartnerAndCustomer={this.setCurrentPartnerAndCustomer} setCurrentPartnerId={this.setCurrentPartnerId} setCurrentCustomerId={this.setCurrentCustomerId} customers={this.state.customerList} setActivePath={this.setActivePath}  userOrg={this.state.loggedInCustomerOrganization} />
                                                                } />
                                                                <Route exact path="/alertQuestions" element = {
                                                                    <AlertQuestions  currentPartnerId={this.state.currentPartnerId} currentCustomerId={this.state.currentCustomerId} setCurrentPartnerAndCustomer={this.setCurrentPartnerAndCustomer} setCurrentPartnerId={this.setCurrentPartnerId} setCurrentCustomerId={this.setCurrentCustomerId} customers={this.state.customerList} setActivePath={this.setActivePath}  userOrg={this.state.loggedInCustomerOrganization} />
                                                                } />
                                                                <Route exact path="/map" element = {
                                                                    <Map  currentPartnerId={this.state.currentPartnerId} isPartnerAdmin={this.state.isPartnerAdmin} currentCustomerId={this.state.currentCustomerId} setCurrentCustomerId={this.setCurrentCustomerId} partnerList={this.state.partnerList} setActivePath={this.setActivePath}  />
                                                                } />
                                                                <Route exact path="/scheduleRepairs" element = {
                                                                    <ScheduleRepairs currentCustomerId={this.state.currentCustomerId}  currentPartnerId={this.state.currentPartnerId} currentAccount={this.state.currentAccount} userId={this.state.userId} />
                                                                } />
                                                                <Route exact path='/currentJob' element = {
                                                                    <CurrentJob currentPartnerId={this.state.currentPartnerId} currentCustomerId={this.state.currentCustomerId} setActivePath={this.setActivePath}  setUnsavedChanges={this.setUnsavedChanges} showUnsavedModal={this.state.showUnsavedModal} nextPath={this.state.nextPath}  />
                                                                } />
                                                                <Route exact path="/account" element = {
                                                                    <Account   onLogOff={this.onLogOff} roles={this.state.roles} currentPartnerId={this.state.currentPartnerId} currentCustomerId={this.state.currentCustomerId} onContinueFromAccount={this.onContinueFromAccount} setCurrentPartnerAndCustomer={this.setCurrentPartnerAndCustomer} loggedInCustomerOrganization={this.state.loggedInCustomerOrganization} isPartnerAdmin={this.state.isPartnerAdmin} reparentStatus={this.state.reparentStatus} setReparent={this.setReparent} passwordEmailSent={this.passwordEmailSent} endForgotPassword={this.endForgotPassword} isMobile={this.state.isMobile} hasServiceTitan={this.state.hasServiceTitan} hasBilling={this.state.hasBilling} attributeList={this.state.attributeList} setUnsavedChanges={this.setUnsavedChanges} showUnsavedModal={this.state.showUnsavedModal} nextPath={this.state.nextPath} setActivePath={this.setActivePath} />
                                                                } />
                                                                <Route exact path="/customerAlerts" element = {
                                                                    <CustomerAlerts  currentPartnerId={this.state.currentPartnerId} currentCustomerId={this.state.currentCustomerId} setCurrentPartnerAndCustomer={this.setCurrentPartnerAndCustomer} setCurrentPartnerId={this.setCurrentPartnerId} setCurrentCustomerId={this.setCurrentCustomerId} customers={this.state.customerList} setActivePath={this.setActivePath}  userOrg={this.state.loggedInCustomerOrganization} />
                                                                } />
                                                                <Route path="/home" element = {
                                                                    <Home  currentPartnerId={this.state.currentPartnerId} currentCustomerId={this.state.currentCustomerId}  hasServiceTitan={this.state.hasServiceTitan} hasBilling={this.state.hasBilling} setUnsavedChanges={this.setUnsavedChanges} showUnsavedModal={this.state.showUnsavedModal} nextPath={this.state.nextPath} setActivePath={this.setActivePath} partnerList={this.state.partnerList} />
                                                                } />
                                                                <Route exact path="/assets" element = {
                                                                    <Assets
                                                                        setActivePath={this.setActivePath}
                                                                        serviceTier={this.state.serviceTier}
                                                                        hasServiceTier={this.state.hasServiceTier}
                                                                        currentCustomerId={this.state.currentCustomerId}
                                                                        currentAccount={this.state.currentAccount}
                                                                        faveAssetPath={this.state.faveAssetPath}
                                                                        
                                                                        favoriteAssets={this.state.favoriteAssets}
                                                                        setAssetFavorites={this.setAssetFavorites}
                                                                        isPartnerAdmin={this.state.isPartnerAdmin}
                                                                        isAdmin={this.state.roles.includes("ADMIN")}
                                                                        isActive={this.state.isActive}
                                                                        userOrg={this.state.loggedInCustomerOrganization}
                                                                        userName={this.state.currentAccount.email}
                                                                        setUnsavedChanges={this.setUnsavedChanges}
                                                                        showUnsavedModal={this.state.showUnsavedModal}
                                                                        nextPath={this.state.nextPath}
                                                                    />
                                                                } />
                                                                <Route exact path="/devices" element = {
                                                                    <Devices  currentCustomerId={this.state.currentCustomerId}  />
                                                                } />
                                                                <Route exact path="/installCheck" element = {
                                                                    <InstallCheck  currentCustomerId={this.state.currentCustomerId} roles={this.state.roles}  source="App" />
                                                                } />
                                                                <Route exact path="/provisioning" element = {
                                                                    <Provisioning  currentCustomerId={this.state.currentCustomerId} roles={this.state.roles}  />
                                                                } />
                                                                <Route exact path="/billingInfo" element = {
                                                                    <BillingInfo  onLogOff={this.onLogOff} currentCustomerId={this.state.currentCustomerId}  customers={this.state.customerList} />
                                                                } />
                                                                <Route exact path="/accountManagement" element = {
                                                                    <AccountManagement  currentCustomerId={this.state.currentCustomerId} currentPartnerId={this.state.currentPartnerId}  roles={this.state.roles} currentAccount={this.state.currentAccount} serviceTier={this.state.serviceTier} hasServiceTier={this.state.hasServiceTier} isPartnerAdmin={this.state.isPartnerAdmin} attributeList={this.state.attributeList} setUnsavedChanges={this.setUnsavedChanges} showUnsavedModal={this.state.showUnsavedModal} nextPath={this.state.nextPath} setActivePath={this.setActivePath} />
                                                                } />
                                                                <Route exact path="/users" element = {
                                                                    <Users  currentPartnerId={this.state.currentPartnerId} isPartnerAdmin={this.state.isPartnerAdmin} isPortalAdmin={this.state.isPortalAdmin}  roles={this.state.roles} />
                                                                } />
                                                                <Route exact path="/useraccount" element = {
                                                                    <UserAccount  userId={this.state.userId} currentAccount={this.state.currentAccount} loggedInCustomerOrganizationId={this.state.loggedInCustomerOrganizationId}  updateAccountInfo={this.setAccountInfo} onContinueFromUserAccount={this.onContinueFromUserAccount} isPartnerAdmin={this.state.isPartnerAdmin} logo={this.state.logo}  setUnsavedChanges={this.setUnsavedChanges} showUnsavedModal={this.state.showUnsavedModal} nextPath={this.state.nextPath} setActivePath={this.setActivePath} />
                                                                } />
                                                                <Route exact path="/addPartner" element = {
                                                                    <AddPartner  roles={this.state.roles}   loggedInCustomerOrganization={this.state.loggedInCustomerOrganization} isPartnerAdmin={this.state.isPartnerAdmin} partners={this.state.partnerList} setPartnerList={list => { this.setState({ partnerList: list }); }} onContinueFromAddPartner={this.onContinueFromAddPartner} setActivePath={this.setActivePath} setCurrentPartnerId={this.setCurrentPartnerId} switchToNewPartner={this.switchToNewPartner/*(list, partner) => { this.switchToNewPartner(list, partner); }*/} setUnsavedChanges={this.setUnsavedChanges} showUnsavedModal={this.state.showUnsavedModal} nextPath={this.state.nextPath} />
                                                                } />
                                                                <Route exact path="/emailConfirmation" element = {
                                                                    <ConfirmationEmail   />
                                                                } />
                                                                <Route exact path="/emailStatus" element = {
                                                                    <EmailStatus   />
                                                                } />
                                                                <Route exact path="/inviteHomeowner" element = {
                                                                    <InviteHomeowner   currentCustomerId={this.state.currentCustomerId} currentPartnerId={this.state.currentPartnerId} partnerList={this.state.partnerList} hasBilling={this.state.hasBilling} loggedInCustomerOrganization={this.state.loggedInCustomerOrganization} rentalAgreementCustomerValue={this.state.rentalAgreementCustomerValue} attributeList={this.state.attributeList} setUnsavedChanges={this.setUnsavedChanges} showUnsavedModal={this.state.showUnsavedModal} nextPath={this.state.nextPath} setActivePath={this.setActivePath} />
                                                                } />
                                                                <Route exact path="/inviteAdmins" element = {
                                                                    <InviteAdmins   currentCustomerId={this.state.currentCustomerId} currentPartnerId={this.state.currentPartnerId} hasBilling={this.state.hasBilling} loggedInCustomerOrganization={this.state.loggedInCustomerOrganization} rentalAgreementCustomerValue={this.state.rentalAgreementCustomerValue} attributeList={this.state.attributeList} setActivePath={this.setActivePath} />
                                                                } />
                                                                <Route exact path="/energyMetrics" element = {
                                                                    <PartnerEnergyMetrics   currentCustomerId={this.state.currentCustomerId} currentPartnerId={this.state.currentPartnerId} roles={this.state.roles} loggedInCustomerOrganization={this.state.loggedInCustomerOrganization} />
                                                                } />
                                                                <Route exact path="/installReport" element = {
                                                                    <InstallReport   currentCustomerId={this.state.currentCustomerId} currentPartnerId={this.state.currentPartnerId} roles={this.state.roles} loggedInCustomerOrganization={this.state.loggedInCustomerOrganization} partners={this.state.partnerList} />
                                                                } />
                                                                <Route exact path="/failedInstallsReport" element = {
                                                                    <FailedInstalls  isLoading={this.state.loading} currentCustomerId={this.state.currentCustomerId} currentPartnerId={this.state.currentPartnerId} roles={this.state.roles} loggedInCustomerOrganization={this.state.loggedInCustomerOrganization} partners={this.state.partnerList} />
                                                                } />
                                                                <Route exact path="/deletedAccounts" element = {
                                                                    <DeletedAccounts   currentCustomerId={this.state.currentCustomerId} currentPartnerId={this.state.currentPartnerId} roles={this.state.roles} loggedInCustomerOrganization={this.state.loggedInCustomerOrganization} _isMounted={ this._isMounted } />
                                                                } />
                                                                <Route exact path="/deletedSystems" element = {
                                                                    <DeletedSystems   currentCustomerId={this.state.currentCustomerId} currentPartnerId={this.state.currentPartnerId} roles={this.state.roles} loggedInCustomerOrganization={this.state.loggedInCustomerOrganization} _isMounted={ this._isMounted } />
                                                                } />
                                                                <Route exact path="/iottest" element = {
                                                                    <IotTest  currentAccount={this.state.currentAccount} updateAccountInfo={this.setAccountInfo} isPartnerAdmin={this.state.isPartnerAdmin} logo={this.state.logo}  />
                                                                } />
                                                                <Route exact path="/accessKeyTool" element = {
                                                                    <AccessKeyTool  currentAccount={this.state.currentAccount} updateAccountInfo={this.setAccountInfo} isPartnerAdmin={this.state.isPartnerAdmin} logo={this.state.logo}  />
                                                                } />
                                                                <Route exact path="/manageAssets" element = {
                                                                    <ManageAssets  currentAccount={this.state.currentAccount} isPartnerAdmin={this.state.isPartnerAdmin} logo={this.state.logo}  />
                                                                } />
                                                                <Route exact path="/otaLogs" element = {
                                                                    <OTALogs   />
                                                                } />
                                                                <Route exact path="/forgotPassword" element = {
                                                                    <ForgotPassword  setActivePath={this.setActivePath} />
                                                                } />
                                                                <Route exact path="/manageFavorites" element = {
                                                                    <ManageFavorites  onSave={this.onSaveFaves} partners={this.state.partnerList} customers={this.state.customerList} favorites={this.state.favoriteCustomers/*favoritesList*/} favoriteCustomers={this.state.favoriteCustomers} favoriteAssets={this.state.favoriteAssets} userName={this.state.currentAccount.email} selectedPartner={this.state.currentPartnerId} setFavoritesList={this.setFavoritesList} isLoading={this.state.isLoading} setUnsavedChanges={this.setUnsavedChanges} showUnsavedModal={this.state.showUnsavedModal} nextPath={this.state.nextPath} />
                                                                } />
                                                                <Route exact path="/userManagement" element = {
                                                                    <UserManagement   />
                                                                } />
                                                                <Route exact path="/operationalDashboard" element = {
                                                                    <OperationalDashboard setActivePath={this.setActivePath} loggedInOrg={this.state.loggedInCustomerOrganization} currentPartnerId={this.state.currentPartnerId} showAllTraining={this.state.showAllTraining} setShowAllTraining={this.setShowAllTraining}/>
                                                                } />
                                                                <Route exact path="/airConditionerOnboarding" element = {
                                                                    <ACOnboarding   setActivePath={this.setActivePath} loggedInOrg={this.state.loggedInCustomerOrganization} currentPartnerId={this.state.currentPartnerId} showAllTraining={this.state.showAllTraining} setShowAllTraining={this.setShowAllTraining} />
                                                                } />
                                                                <Route exact path="/acAssetTrainingReview" element = {
                                                                    <ACAssetTrainingReview   loggedInOrg={this.state.loggedInCustomerOrganization} currentPartnerId={this.state.currentPartnerId} showAllTraining={this.state.showAllTraining} setShowAllTraining={this.setShowAllTraining} getGraphData={this.getGraphData} getLastGraphData={this.getLastGraphData}/>
                                                                } />
                                                                <Route exact path="/acTrainingAlertCountReview" element = {
                                                                    <ACAlertTrainingReview   loggedInOrg={this.state.loggedInCustomerOrganization} currentPartnerId={this.state.currentPartnerId} showAllTraining={this.state.showAllTraining} setShowAllTraining={this.setShowAllTraining} getGraphData={this.getGraphData} getLastGraphData={this.getLastGraphData}/>
                                                                } />
                                                                <Route exact path="/furnaceOnboarding" element = {
                                                                    <FurnaceOnboarding   setActivePath={this.setActivePath} loggedInOrg={this.state.loggedInCustomerOrganization} currentPartnerId={this.state.currentPartnerId} showAllTraining={this.state.showAllTraining} setShowAllTraining={this.setShowAllTraining} />
                                                                } />
                                                                <Route exact path="/furnaceAssetTrainingReview" element = {
                                                                    <FurnaceAssetTrainingReview   loggedInOrg={this.state.loggedInCustomerOrganization} currentPartnerId={this.state.currentPartnerId} currentAccount={this.state.currentAccount} showAllTraining={this.state.showAllTraining} setShowAllTraining={this.setShowAllTraining} getGraphData={this.getGraphData} getLastGraphData={this.getLastGraphData}/>
                                                                } />
                                                                <Route exact path="/furnaceTrainingAlertCountReview" element = {
                                                                    <FurnaceAlertTrainingReview   loggedInOrg={this.state.loggedInCustomerOrganization} currentPartnerId={this.state.currentPartnerId} showAllTraining={this.state.showAllTraining} setShowAllTraining={this.setShowAllTraining} getGraphData={this.getGraphData} getLastGraphData={this.getLastGraphData}/>
                                                                } />
                                                                <Route exact path="/airHandlerOnboarding" element = {
                                                                    <AirHandlerOnboarding  setActivePath={this.setActivePath} loggedInOrg={this.state.loggedInCustomerOrganization} currentPartnerId={this.state.currentPartnerId} showAllTraining={this.state.showAllTraining} setShowAllTraining={this.setShowAllTraining} />
                                                                } />
                                                                <Route exact path="/airHandlerAssetTrainingReview" element = {
                                                                    <AirHandlerAssetTrainingReview  loggedInOrg={this.state.loggedInCustomerOrganization} currentPartnerId={this.state.currentPartnerId} showAllTraining={this.state.showAllTraining} setShowAllTraining={this.setShowAllTraining} getGraphData={this.getGraphData} getLastGraphData={this.getLastGraphData}/>
                                                                } />
                                                                <Route exact path="/airHandlerTrainingAlertCountReview" element = {
                                                                    <AirHandlerAlertTrainingReview  loggedInOrg={this.state.loggedInCustomerOrganization} currentPartnerId={this.state.currentPartnerId} showAllTraining={this.state.showAllTraining} setShowAllTraining={this.setShowAllTraining} getGraphData={this.getGraphData} getLastGraphData={this.getLastGraphData}/>
                                                                } />
                                                                <Route path="/subscriptions" element = {
                                                                    <Subscriptions  currentPartnerId={this.state.currentPartnerId}   loggedInCustomerOrganization={this.state.loggedInCustomerOrganization} attributeList={this.state.attributeList} />
                                                                } />
                                                                <Route path="/subscriptions/:type" element = {
                                                                    <Subscriptions  currentPartnerId={this.state.currentPartnerId}   loggedInCustomerOrganization={this.state.loggedInCustomerOrganization} attributeList={this.state.attributeList} />
                                                                } />
                                                                <Route exact path="/alertMessaging" element = {
                                                                    <AlertMessaging  currentPartnerId={this.state.currentPartnerId}  roles={this.state.roles} _isMounted={this._isMounted} showUnsavedModal={this.state.showUnsavedModal} setActivePath={this.setActivePath} onContinueFromComponent={this.onContinueFromComponent} />
                                                                } />
                                                                <Route exact path="/customizeAlerts" element = {
                                                                    <CustomizeAlerts  currentPartnerId={this.state.currentPartnerId}  roles={this.state.roles} _isMounted={this._isMounted} showUnsavedModal={this.state.showUnsavedModal} setActivePath={this.setActivePath} onContinueFromComponent={this.onContinueFromComponent} />
                                                                } />
                                                                <Route exact path="/training" element = {
                                                                    <Training currentUserId={this.state.userId}  roles={this.state.roles} />
                                                                } />
                                                            </Route>
                                                        </Routes>

                                                        <Routes>
                                                            <Route element={<ProtectedRoute isAuthenticated={this.state.loggedIn} />}>
                                                                <Route exact path="/fleet" element = {
                                                                    <FleetStateProvider currentPartnerId={this.state.currentPartnerId}>
                                                                        <Fleet  setActivePath={this.setActivePath} roles={this.state.roles} />
                                                                    </FleetStateProvider>
                                                                } />
                                                                <Route exact path="/upgradeOpportunities" element = {
                                                                    <FleetStateProvider currentPartnerId={this.state.currentPartnerId}>
                                                                        <UpgradeOpportunities  roles={this.state.roles} />
                                                                    </FleetStateProvider>
                                                                } />
                                                            </Route>
                                                        </Routes>
                                                        
                                                    </Sidebar>
                                                </div>

                                                <IdleModal onClick={this.onShowIdleModal} show={this.state.showIdleModal} />
                                            </div>
                                        }

                                </React.Fragment>
                            </div>
                            </DataProvider>
                        ) : <Login onLogonChange={this.onLoggedInUserChange} onForgotPassword={this.onForgotPassword} />;
    }
}
export default withRouter(App)
